import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDptiaOm_7v9nomQocJe01EBvyuCRHTdLU",
  authDomain: "jardim-blauth.firebaseapp.com",
  databaseURL: "https://jardim-blauth.firebaseio.com",
  projectId: "jardim-blauth",
  storageBucket: "jardim-blauth.appspot.com",
  messagingSenderId: "13413597281",
  appId: "1:13413597281:web:06d98c76d730f477d66a20",
  measurementId: "G-37BHY4T6EH",
};

const app = initializeApp(firebaseConfig);

const FirebaseStorage = getStorage(app);
const FirebaseAuth = getAuth(app);
const Firestore = getFirestore(app);

export { Firestore, FirebaseAuth, FirebaseStorage };
