import { Firestore } from "./Firebase";
import { productType, Company, Product, Category, Coupon } from "../Services/Datatypes";
import { collection, query, orderBy, getDocs, addDoc, doc, getDoc, updateDoc, setDoc, deleteDoc  } from 'firebase/firestore';


async function fetchProducts() {
  console.log("fetchProducts");
  var products = [];
  try {
    const productsQuery = query(collection(Firestore, "products"), orderBy("name"));
    const snap = await getDocs(productsQuery);
    snap.forEach((product) => {
      let p = product.data();
      p.id = product.id;
      if (!p.hasOwnProperty("type")) {
        if (p.is_bulk) {
          p.type = productType.BULK;
          p.byWeight = true;
        } else if (p.needs_weighing) p.type = productType.NEEDS_WEIGHING;
        else p.type = productType.PER_UNIT;
      }
      products.push(p);
    });
  } catch (err) {
    console.log("Error getting products", err);
    return { ok: false };
  }
  console.log(products);
  return { products: products, ok: true };
}

async function addProduct(product) {
  console.log("addProduct");
  try {
    const docRef = await addDoc(collection(Firestore, "products"), {
      name: product.name,
      category: product.category,
      image: product.image,
      price: product.price,
      description: product.description,
      type: product.type,
      is_available: product.is_available,
    });
    console.log("Document written with ID: ", docRef.id);
    product.id = docRef.id;
  } catch (error) {
    console.error("Error adding document: ", error);
    return { ok: false };
  }
  return { ok: true, product: product };
}

async function editProduct(product) {
  console.log("editProduct");
  try {
    const productRef = doc(Firestore, "products", product.id);
    await setDoc(productRef, {
      name: product.name,
      category: product.category,
      image: product.image,
      price: product.price,
      description: product.description,
      type: product.type,
      is_available: product.is_available,
    });
    console.log("Product edited: id ", product.id);
  } catch (error) {
    console.error("Error editing product: ", error);
    return { ok: false };
  }
  return { ok: true };
}

async function deleteProduct(id) {
  console.log("deleteProduct");
  try {
    const productRef = doc(Firestore, "products", id);
    await deleteDoc(productRef);
    console.log("Document deleted: id ", id);
    return { ok: true };
  } catch (error) {
    console.error("Error deleting document: ", error);
    return { ok: false };
  }
}

type fetchCompanyResponse = {
  company: Company;
  ok: Boolean;
};

async function fetchCompany() {
  console.log("fetchCompany");
  try {
    const companyRef = doc(Firestore, "company", "9JbaNJeANDgX9CXnbVaI");
    const companyDoc = await getDoc(companyRef);
    if (!companyDoc.exists) {
      console.log("No such document!");
      return { company: undefined, ok: false };
    }
    let companyData = companyDoc.data();
    let company = {
      name: companyData.name,
      phone: companyData.phone,
      categories: companyData.categories,
      shippingFee: companyData.shippingFee,
      coupons: companyData.coupons,
    };
    return { company: company, ok: true };
  } catch (err) {
    console.log("Error getting company", err);
    return { company: undefined, ok: false };
  }
}

async function editCompany(company) {
  console.log("editCompany");
  try {
    const companyRef = doc(Firestore, "company", "9JbaNJeANDgX9CXnbVaI");
    await updateDoc(companyRef, {
      name: company.name,
      phone: company.phone,
      shippingFee: company.shippingFee,
    });
    console.log("Company edited");
  } catch (error) {
    console.error("Error editing company: ", error);
    return { ok: false };
  }
  return { ok: true };
}


async function editCompanyCategories(categories) {
  console.log("editCompanyCategories");
  try {
    const companyRef = doc(Firestore, "company", "9JbaNJeANDgX9CXnbVaI");
    await updateDoc(companyRef, {
      categories: categories,
    });
    console.log("Categories edited");
  } catch (error) {
    console.error("Error editing company categories: ", error);
    return { ok: false };
  }
  return { ok: true };
}

async function editCompanyCoupons(coupons) {
  console.log("editCompanyCoupons");
  try {
    const companyRef = doc(Firestore, "company", "9JbaNJeANDgX9CXnbVaI");
    await updateDoc(companyRef, {
      coupons: coupons,
    });
    console.log("Coupons edited");
  } catch (error) {
    console.error("Error editing company coupons: ", error);
    return { ok: false };
  }
  return { ok: true };
}

// async function fetchProductsArray() {
//   console.log("fetchProductsArray ");
//   console.time("fetchProductsArray");
//   var products;
//   try {
//     let snap = await Firestore.collection("company")
//       .doc("allProductsArray")
//       .get();
//     products = snap.data().products;
//     products.forEach((product) => {
//       if (product.type === productType.BULK) product.byWeight = true;
//       else product.byWeight = false;
//     });
//     console.timeEnd("fetchProductsArray");
//   } catch (err) {
//     console.log("Error getting fetchProductsArray", err);
//     return { ok: false };
//   }
//   return { products: products, ok: true };
// }

async function editAllProductsArray(products) {
  console.log("editAllProductsArray");

  if (!products || products.length === 0) {
    console.warn("editAllProductsArray: products list is empty = ", products);
    return { ok: false };
  }

  try {
    const companyRef = doc(Firestore, "company", "allProductsArray");
    await setDoc(companyRef, {
      count: products.length,
      products: products,
    });
    console.log("products array edited");
  } catch (error) {
    console.error("Error editing company products array: ", error);
    return { ok: false };
  }
  return { ok: true };
}

export {
  fetchCompany,
  editCompany,
  editCompanyCategories,
  fetchProducts,
  editProduct,
  deleteProduct,
  addProduct,
  editAllProductsArray,
  editCompanyCoupons,
};
