import { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ModalForm from "./Components/ModalForm";
import DataTable from "./Components/DataTable";
import CategoriesEditor from "./Components/CategoriesEditor";
import CouponsEditor from "./Components/CouponsEditor";
import CompanyEditor from "./Components/CompanyEditor";
import { CSVLink } from "react-csv";
import {
  fetchProducts,
  fetchCompany,
  editCompany,
  editCompanyCategories,
  editProduct,
  editAllProductsArray,
  editCompanyCoupons,
} from "./repo/Repo";
import { ToastContainer, toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Company, Category, Product, Coupon } from "./Services/Datatypes";

import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";

function App() {
  const [items, setItems] = useState([]);
  const [company, setCompany] = useState<Company>(undefined);

  // Runs every time "items" is updated.
  useEffect(() => {
    editAllProductsArray(items);
  }, [items]);

  const getItems = async () => {
    console.log("getItems");
    let res = await fetchProducts();
    if (res.ok) {
      toast.success("Produtos carregados com sucesso!");
      console.log(res);
      setItems(res.products);
    } else {
      toast.error("Erro ao carregar produtos");
    }
  };

  const getCompany = async () => {
    console.log("getCompany");
    let res = await fetchCompany();
    if (res.ok) {
      console.log(res);
      setCompany(res.company);
      toast.success(res.company.name + " carregada com sucesso!");
    } else {
      toast.error("Erro ao carregar empresa");
    }
  };

  const addItemToState = (product, notify = true) => {
    if (notify) toast.success("Produto adicionado: " + product.name);
    setItems([...items, product]);
  };

  const updateState = (product, notify = true) => {
    console.log("updateState");
    if (notify) toast.success("Produto atualizado: " + product.name);
    const itemIndex = items.findIndex((data) => data.id === product.id);
    const newArray = [
      ...items.slice(0, itemIndex),
      product,
      ...items.slice(itemIndex + 1),
    ];
    setItems(newArray);
  };

  const deleteItemFromState = (product, notify = true) => {
    console.log("deleteItemFromState");
    if (notify) toast.success("Produto deletado: " + product.name);
    const updatedItems = items.filter((item) => item.id !== product.id);
    setItems(updatedItems);
  };

  const saveCategories = (
    oldCategories: Array<Category>,
    newCategories: Array<Category>
  ) => {
    console.log("saveCategories");
    let oldCategoriesLength = oldCategories.length;
    let products = items;
    newCategories.forEach((newCategory: Category, i: number) => {
      if (i < oldCategoriesLength) {
        let oldCategory = oldCategories[i];
        if (newCategory !== oldCategory)
          renameProductsCategory(products, oldCategory, newCategory);
      }
    });
    newCategories = newCategories.filter((c: Category) => c !== "");
    editCompanyCategories(newCategories);
    setCompany({ ...company, categories: newCategories });
    toast.success("Categorias atualizadas");
  };

  const saveCoupons = (coupons: Coupon[]) => {
    console.log("saveCoupons");
    editCompanyCoupons(coupons);
    setCompany({ ...company, coupons: coupons });
    toast.success("Cupons atualizados");
  };

  const updateCoupons = (coupons: Coupon[]) => {
    console.log("updateCoupons");
    setCompany({ ...company, coupons: coupons });
  };

  const saveCompany = (newCompany: Company) => {
    console.log("saveCompany");
    setCompany(newCompany);
    editCompany(newCompany);
    toast.success("Empresa atualizada");
  };

  function renameProductsCategory(
    products: Array<Product>,
    old_category: Category,
    new_category: Category
  ) {
    products.forEach((product) => {
      console.log(old_category);
      if (product.category === old_category) {
        product.category = new_category;
        updateState(product, false);
        editProduct(product);
      }
    });
  }

  useEffect(() => {
    getItems();
    getCompany();
  }, []);

  if (company === undefined) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <Container className="App">
        <Row>
          <Col>
            <h3 style={{ margin: "20px 0" }}>Admin Jardim Blauth</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs>
              <TabList>
                <Tab>Produtos</Tab>
                <Tab>Categorias</Tab>
                {/* <Tab>Cupons</Tab> */}
                <Tab>Empresa</Tab>
              </TabList>

              <TabPanel>
                <h2>Produtos</h2>
                {/* <CSVLink
                  filename={"db.csv"}
                  color="primary"
                  style={{ float: "left", marginRight: "10px" }}
                  className="btn btn-primary"
                  data={items}
                >
                  Download CSV
                </CSVLink> */}
                <ModalForm
                  buttonLabel="Adicionar Produto"
                  categories={company.categories}
                  addItemToState={addItemToState}
                />
                <br />
                <br />
                <DataTable
                  items={items}
                  updateState={updateState}
                  deleteItemFromState={deleteItemFromState}
                  categories={company.categories}
                />
              </TabPanel>
              <TabPanel>
                <h2>Categorias</h2>
                <CategoriesEditor
                  categories={company.categories}
                  saveCategories={saveCategories}
                />
              </TabPanel>
              {/* <TabPanel>
                <h2>Cupons</h2>
                <CouponsEditor
                  coupons={company.coupons}
                  updateCoupons={updateCoupons}
                  saveCoupons={saveCoupons}
                />
              </TabPanel> */}
              <TabPanel>
                <h2>Empresa</h2>
                <CompanyEditor company={company} saveCompany={saveCompany} />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
