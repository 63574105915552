export const productType = {
  BULK: "BULK",
  NEEDS_WEIGHING: "NEEDS_WEIGHING",
  PER_UNIT: "PER_UNIT",
};

export const productTypeText = {
  BULK: "Granel",
  NEEDS_WEIGHING: "Necessita Pesagem",
  PER_UNIT: "Unidade",
};

export function getTypeFromText(text: string) {
  switch (text) {
    case productTypeText.BULK:
      return productType.BULK;
    case productTypeText.NEEDS_WEIGHING:
      return productType.NEEDS_WEIGHING;
    case productTypeText.PER_UNIT:
      return productType.PER_UNIT;
    default:
      return null;
  }
}

export function getTextFromType(type: string) {
  switch (type) {
    case productType.BULK:
      return productTypeText.BULK;
    case productType.NEEDS_WEIGHING:
      return productTypeText.NEEDS_WEIGHING;
    case productType.PER_UNIT:
      return productTypeText.PER_UNIT;
    default:
      return null;
  }
}

export type Company = {
  name: string;
  phone: string;
  categories: string[];
  shippingFee: number;
  coupons: Coupon[];
};

export type Category = string;

export type Product = {
  id: string;
  name: string;
  description: string;
  category: string;
  price: number;
  is_available: boolean;
  type: string;
  image: string;
};

export const couponType = {
  PERCENTAGE: "PERCENTAGE",
  FIXED: "FIXED",
};
export const couponTypeText = {
  PERCENTAGE: "Porcentagem",
  FIXED: "Fixo",
};

export function getCouponTypeFromText(text: string) {
  switch (text) {
    case couponTypeText.PERCENTAGE:
      return couponType.PERCENTAGE;
    case couponTypeText.FIXED:
      return couponType.FIXED;
    default:
      return null;
  }
}

export type Coupon = {
  code: string;
  discount: number;
  type: string;
  is_active: boolean;
}
