import { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { addProduct, editProduct } from "../repo/Repo";
import { toast } from "react-toastify";
import {
  getTypeFromText,
  productTypeText,
  getTextFromType,
} from "../Services/Datatypes";
import "../styles/index.css";

function AddEditForm(props) {
  const [form, setValues] = useState({
    id: "",
    name: "",
    description: "",
    category: "",
    price: 0,
    is_available: true,
    type: "",
    image:
      "https://res.cloudinary.com/dwjwz8hlc/image/upload/v1599774784/jardimblauth/sem-foto_cen4hs.gif",
  });

  const types = [
    productTypeText.NEEDS_WEIGHING,
    productTypeText.PER_UNIT,
    productTypeText.BULK,
  ];
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const toggleType = () => setTypeDropdownOpen((prevState) => !prevState);
  const toggleCategory = () =>
    setCategoryDropdownOpen((prevState) => !prevState);

  const onChange = (e) => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onChangePrice = (e) => {
    let price = e.target.value.replace(/,/g, ".");
    setValues({
      ...form,
      [e.target.name]: price,
    });
  };
  const onCheckboxChange = (e) => {
    setValues({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const submitFormAdd = async (e) => {
    e.preventDefault();
    const product = {
      name: form.name,
      category: form.category,
      description: form.description,
      price: Number(form.price),
      is_available: form.is_available,
      type: getTypeFromText(form.type),
      image: form.image,
    };
    let res = await addProduct(product);
    console.log("addProduct");
    console.log(res);
    if (res.ok) {
      props.addItemToState(res.product);
      props.toggle();
    } else {
      toast.error("error on submitFormAdd");
    }
  };

  const submitFormEdit = async (e) => {
    e.preventDefault();
    const product = {
      id: form.id,
      name: form.name,
      category: form.category,
      description: form.description,
      price: Number(form.price),
      is_available: form.is_available,
      type: getTypeFromText(form.type),
      image: form.image,
    };
    let res = await editProduct(product);
    if (res.ok) {
      props.updateState(product);
      props.toggle();
    } else {
      toast.error("error on submitFormEdit");
    }
  };

  const checkUploadResult = (resultEvent) => {
    if (resultEvent.event === "success") {
      let url = resultEvent.info.secure_url;
      console.log(url);
      setValues({ ...form, image: url });
    }
  };

  const showUploadWidget = () => {
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: "dwjwz8hlc",
        uploadPreset: "weborder-admin",
        folder: "jardimblauth",
        multiple: false,
        maxFileSize: 0.6 * 1000000, // 0.6MB
        sources: ["local", "url", "image_search", "camera"],
        language: "en",
        text: {
          en: {
            or: "ou",
            local: {
              browse: "Procurar",
              main_title: "Faça Upload",
              dd_title_single: "Arraste uma imagem aqui",
              drop_title_single: "Solte um arquivo para fazer upload",
            },
          },
        },
      },
      (error, result) => {
        checkUploadResult(result);
      }
    );
    widget.open();
  };

  useEffect(() => {
    if (props.item) {
      const {
        id,
        name,
        category,
        description,
        price,
        image,
        is_available,
      } = props.item;
      const type = getTextFromType(props.item.type);
      setValues({
        id,
        name,
        category,
        description,
        price,
        image,
        is_available,
        type,
      });
    }
  }, []);

  return (
    <Form onSubmit={props.item ? submitFormEdit : submitFormAdd}>
      {/* <FormGroup>
        <Label for="cod">Cod</Label>
        <Input type="number" name="cod" id="cod"
          onChange={onChange} value={form.cod === null ? 0 : form.cod} />
      </FormGroup> */}
      <FormGroup>
        <Label for="name">Nome</Label>
        <Input
          type="text"
          name="name"
          id="name"
          maxLength="35"
          onChange={onChange}
          value={form.name === null ? "" : form.name}
        />
      </FormGroup>

      <FormGroup>
        <Label for="category">Categoria</Label>
        <Dropdown isOpen={categoryDropdownOpen} toggle={toggleCategory}>
          <DropdownToggle name="category" caret>
            {form.category}
          </DropdownToggle>
          <DropdownMenu>
            {props.categories.map((item, i) => {
              return (
                <DropdownItem
                  name="category"
                  key={i}
                  value={item}
                  onClick={onChange}
                >
                  {item}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>

      <FormGroup>
        <Label for="description">Descrição</Label>
        <Input
          type="text"
          name="description"
          id="description"
          onChange={onChange}
          value={form.description === null ? "" : form.description}
        />
      </FormGroup>

      <FormGroup>
        <Label for="price">Preço</Label>
        <Input
          type="text"
          name="price"
          id="price"
          onChange={onChangePrice}
          pattern="^-?[0-9]\d*\.?\d*$"
          value={form.price === null ? 0.0 : form.price}
        />
      </FormGroup>

      <FormGroup>
        <Label for="image">Imagem</Label>
        <br />
        <Button
          name="image-upload"
          className="upload-button"
          id="image-upload"
          onClick={showUploadWidget}
        >
          <img alt="product" src={form.image} height="160px" />
          <p className="upload-img-description">
            Clique aqui para fazer upload
          </p>
          {/* <Input
          disabled
          type="url"
          name="image"
          id="image"
          value={form.image === null ? "" : form.image}
        /> */}
        </Button>
      </FormGroup>

      <FormGroup>
        <Label for="is_available">Disponível?</Label>
        <Input
          type="checkbox"
          name="is_available"
          id="is_available"
          onChange={onCheckboxChange}
          value={form.is_available}
          checked={form.is_available}
          className="form-control"
        />
      </FormGroup>

      <br />
      <FormGroup>
        <Label for="type">Tipo</Label>
        <Dropdown isOpen={typeDropdownOpen} toggle={toggleType}>
          <DropdownToggle name="type" caret>
            {form.type}
          </DropdownToggle>
          <DropdownMenu>
            {types.map((item, i) => {
              return (
                <DropdownItem
                  name="type"
                  key={i}
                  value={item}
                  onClick={onChange}
                >
                  {item}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>

      <br></br>
      <Button color="success">Salvar</Button>
    </Form>
  );
}

export default AddEditForm;
