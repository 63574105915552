import React, { useState } from "react";
import { Company } from "../Services/Datatypes";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
// import "../styles/companyEditor.css";

interface Props {
  company: Company;
  saveCompany(company: Company): void;
}

function CompanyEditor(props: Props) {
  const [company, setCompany] = useState(props.company);

  const saveCompany = (e) => {
    company.shippingFee = Number(company.shippingFee);
    console.log("saveCompany: ", company);
    e.preventDefault();
    props.saveCompany(company);
  };

  const onChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePrice = (e) => {
    let price = e.target.value.replace(/,/g, ".");
    setCompany({
      ...company,
      [e.target.name]: price,
    });
  };

  return (
    <div className="company-editor">
      <Form onSubmit={saveCompany}>
        <FormGroup>
          <Label for="name">Nome</Label>
          <Input
            type="text"
            name="name"
            id="name"
            maxLength={35}
            onChange={onChange}
            value={company.name}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="phone">Telefone (WhatsApp)</Label>
          <Input
            type="number"
            name="phone"
            id="phone"
            maxLength={35}
            pattern="^\d+$"
            onChange={onChange}
            value={company.phone}
          />
        </FormGroup>
        <br />
        <FormGroup>
          <Label for="shippingFee">Taxa de Entrega</Label>
          <Input
            type="number"
            name="shippingFee"
            id="shippingFee"
            onChange={onChangePrice}
            pattern="^-?[0-9]\d*\.?\d*$"
            value={company.shippingFee === null ? 0.0 : company.shippingFee}
          />
        </FormGroup>
        <br></br>
        <Button color="success">Salvar</Button>
      </Form>
    </div>
  );
}

export default CompanyEditor;
