import React, { useState } from "react";
import "../styles/categoriesEditor.css";

interface Props {
  categories: string[];
  saveCategories(oldCategories: string[], newCategories: string[]): void;
}

function CategoriesEditor(props: Props) {
  const [newCategories, setNewCategories] = useState(props.categories);

  const removeCategory = (index) => {
    renameCategory(index, "");
  };
  const renameCategory = (index, newName) => {
    setNewCategories(newCategories.map((c, i) => (i === index ? newName : c)));
  };
  const addCategory = () => {
    setNewCategories([...newCategories, ""]);
  };

  return (
    <div className="categories-editor">
      <ul className="categories-list">
        {newCategories.map((category, index) => {
          return (
            <li className="category-item" key={index}>
              <input
                value={category}
                onChange={(e) => renameCategory(index, e.target.value)}
              />
              <span className="delete" onClick={() => removeCategory(index)}>
                x
                </span>
            </li>
          );
        })}
        <li className="category-item new-category">
          <button onClick={addCategory}> + </button>
        </li>
      </ul>
      <button
        className="save-categories"
        onClick={() => props.saveCategories(props.categories, newCategories)}
      >
        Salvar
      </button>
    </div>
  );
}

export default CategoriesEditor;
