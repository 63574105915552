import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import AddEditForm from "./FormAddEdit";
import "../styles/index.css";

interface Props {
  buttonLabel: string;
  categories: string[];
  addItemToState?(product: any, notify?: boolean): void;
  updateState?(product: any, notify?: boolean): void;
  item?: {};
}

function ModalForm(props: Props) {
  const [modal, setModal] = useState(false);
  const label: string = props.buttonLabel;

  const toggle = () => {
    setModal(!modal);
    document.documentElement.style.setProperty("--primary-color", "blue");
  };

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  let button = (
    <Button
      color={label === "Edit" ? "warning" : "success"}
      onClick={toggle}
      style={{ float: "left", marginRight: "10px" }}
    >
      {label}
    </Button>
  );

  if (label === "Edit") var title = "Editar Produto";
  else title = "Adicionar Novo Produto";

  return (
    <div>
      {button}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>
          <AddEditForm
            addItemToState={props.addItemToState}
            updateState={props.updateState}
            toggle={toggle}
            categories={props.categories}
            item={props.item}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalForm;
