// Import FirebaseAuth and firebase.
import { Component } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { FirebaseAuth } from "./repo/Firebase";
import { EmailAuthProvider } from 'firebase/auth';
import App from "./App";
import { Button } from "reactstrap";
import "./styles/index.css";

class Main extends Component {
  private unregisterAuthObserver: () => void;

  private uiConfig = {
    signInFlow: "popup",
    signInOptions: [EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  state = {
    isSignedIn: false,
  };

  componentDidMount() {
    this.unregisterAuthObserver = FirebaseAuth.onAuthStateChanged((user) =>
      this.setState({ isSignedIn: !!user })
    );
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (!this.state.isSignedIn) {
      return (
        <div>
          <h1>Admin Jardim Blauth</h1>
          <p>Fazer Login:</p>
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={FirebaseAuth}
          />
        </div>
      );
    } else {
      return (
        <div>
          <App />
          <br></br>
          <br></br>
          <Button
            className="logout-button"
            color="danger"
            onClick={() => FirebaseAuth.signOut()}
          >
            Logout
          </Button>
        </div>
      );
    }
  }
}

export default Main;
