import React from "react";
import { Table, Button } from "reactstrap";
import ModalForm from "./ModalForm";
import { deleteProduct } from "../repo/Repo";
import { toast } from "react-toastify";
import { getTextFromType } from "../Services/Datatypes";

function DataTable(props) {
  const deleteItem = async (item) => {
    let confirmDelete = window.confirm("Deletar produto?");
    if (confirmDelete) {
      var res = await deleteProduct(item.id);
      if (res.ok) {
        props.deleteItemFromState(item);
      } else {
        toast.error("error on submitFormAdd");
      }
    }
  };

  const items = props.items.map((item) => {
    let isAvailableString = item.is_available ? "✅" : "❌";
    let typeString = getTextFromType(item.type);

    if (isMobile()) {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>
            <div style={{ width: "110px" }}>
              <ModalForm
                buttonLabel="Edit"
                item={item}
                updateState={props.updateState}
                categories={props.categories}
              />{" "}
              <Button color="danger" onClick={() => deleteItem(item)}>
                Del
              </Button>
            </div>
          </td>
        </tr>
      );
    }
    return (
      <tr key={item.id}>
        <th scope="row" style={{ maxWidth: "250px", overflow: "auto" }}>
          {item.name}
        </th>
        <td>{item.category}</td>
        <td>{item.price.toFixed(2)}</td>
        <td>{typeString}</td>
        <td>
          <img
            src={item.image}
            alt={item.name}
            style={{ maxWidth: "100px", maxHeight: "70px" }}
          />{" "}
        </td>
        <td>{isAvailableString}</td>
        <td>
          <div style={{ width: "110px" }}>
            <ModalForm
              buttonLabel="Edit"
              item={item}
              updateState={props.updateState}
              categories={props.categories}
            />{" "}
            <Button color="danger" onClick={() => deleteItem(item)}>
              Del
            </Button>
          </div>
        </td>
      </tr>
    );
  });

  if (props.items.length <= 0) return <p>Carregando produtos...</p>;

  if (isMobile()) {
    return (
      <Table responsive hover>
        <thead>
          <tr>
            <th style={{ maxWidth: "250px" }}>Nome</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{items}</tbody>
      </Table>
    );
  }
  return (
    <Table responsive hover>
      <thead>
        <tr>
          <th style={{ maxWidth: "250px" }}>Nome</th>
          <th>Categoria</th>
          <th>Preço</th>
          <th>Tipo</th>
          <th>Imagem</th>
          <th>Disponível?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </Table>
  );
}

function isMobile() {
  return window.innerWidth <= 500;
}

export default DataTable;
